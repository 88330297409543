@import url("https://fonts.googleapis.com/css?family=Overpass");

* {
  box-sizing: border-box;
  font-family: "Overpass", sans-serif;
}

.tags-input {

  // display: flex;
  // align-items: flex-start;
  // flex-wrap: wrap;
  // min-height: 48px;
  // width: 480px;
  // padding: 0 8px;
  // border: 1px solid rgb(214, 216, 218);
  // border-radius: 6px;
  // &:focus-within {
  // 	border: 1px solid #0052cc;
  // }
  input {
    flex: 1;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 0 10px 0;

    &:focus {
      outline: transparent;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: $primary;

  .tag-title {
    margin-top: 3px;
  }

  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: $primary;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}


.upload-file {
  #attach-file {
    display: none;
  }

  #attach-image {
    display: none;
  }
}

.upload-img-box {
  background: var(--input-bg);
  color: var(--input-color);
  border: 1px solid var(--input-border) !important;
  height: calc(1.25em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.25 !important;
  border-radius: 0.25rem;
  position: relative;

  .upload-icon {
    position: absolute;
    right: 10px;
    top: 9px;
    display: inline-block;
    cursor: pointer;

    .bx {
      font-size: 20px;
      color: var(--dark);
    }
  }
}

.module-tabs {
  border-bottom: 0;

  .nav-link {
    color: var(--dark);

    &.active {
      border: none;
      background: var(--primary);
      color: var(--white) !important;
      border-radius: .25rem;
      font-size: 1rem;
      line-height: 1.25rem;

      a {
        &:hover {
          color: var(--white) !important;
        }
      }
    }

    &:hover {
      border: none;
      background: var(--primary);
      color: var(--white) !important;
      border-radius: .25rem;
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
}

.tabs-module {
  .tab-content {
    margin-top: 20px;
  }
}

.dashboard-container .text {
  font-size: 12px !important;
}

.progress-md {
  margin-bottom: 1.78rem;
}

.d-block-first {
  margin-top: -0.4rem;
}

.d-block-md {
  padding-bottom: 1.35rem !important;
}

.avg-md {
  margin-top: 8.0rem !important;
}

.rattings-section {
  ul {
    padding-left: 0;

    li {
      position: relative;
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
      padding: 24px 0 24px 52px;

      &::before {
        content: "\26DF";
        color: #fff;
        position: absolute;
        font-family: "icomoon", sans-serif;
        left: 0;
        top: 24px;
        font-size: 25px;
        width: 42px;
        height: 42px;
        background: #F38915;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}