// Begin: Button
.btn {
  min-width: size(124);
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $font-size-base,
    $btn-line-height,
    $box-shadow-0
  );
  &.btn-sm {
    font-size: $btn-font-size-sm;
    min-width: size(84);
    line-height: $btn-line-height-sm;
    padding: 0.5rem 1.5rem;
    //or => font-size:size(14);
  }
  &.btn-default {
    border: 1px solid var(--input-border);
    color: var(--dark);
    background: var(--login-card-bg);
    &.btn-bg-white {
      background-color: var(--bg-white);
    }
    &.active {
      border: 1px solid $primary;
      color: var(--login-card-bg);
      background: $primary;
    }
    &:hover {
      color: var(--white);
      background: $primary;
    }
  }
  &.btn-light {
    background-color: $btn-light;
    color: var(--text-color);
  }
  &.btn-copy {
    background: hsl(120, 100%, 93%);
    border-color: #00c642;
    color: $dark;
  }
  &.btn-primary {
    color: $white !important;
  }
  &.btn-secondary {
    color: $white !important;
  }
  &.btn-danger {
    color: $white !important;
  }
  &.btn-lg {
    font-size: $btn-font-size-lg;
    min-width: size(148);
    line-height: $btn-line-height-lg;
    //or => font-size:size(14);
  }
  &.btn-success {
    color: $white;
  }
  &.btn-warning {
    color: $white;
  }
  &.btn-link {
    color: $primary;
    text-decoration: none;
    &.btn-link-primary {
      color: $primary;
    }
    &.btn-link-secondary {
      color: $secondary;
    }
    &.btn-link-success {
      color: $success;
    }
    &.btn-link-info {
      color: $info;
    }
    &.btn-link-warning {
      color: $warning;
    }
    &.btn-link-danger {
      color: $danger;
    }
  }
  &.glow-primary {
    box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.5) !important;
  }
  &.glow-danger {
    box-shadow: 0 3px 8px 0 rgba(255, 91, 92, 0.4) !important;
  }
  &.btn-social {
    position: relative;
    padding-left: 3rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
  }
  &.btn-google {
    color: $white !important;
    background-color: $google;
    border-color: $google;
    &:hover {
      background-color: darken($google, 10) !important;
    }
  }
  &.btn-facebook {
    color: $white !important;
    background-color: $facebook;
    border-color: $facebook;
    &:hover {
      background-color: darken($facebook, 10) !important;
    }
  }
  &.btn-light-primary {
    background: var(--btn-light-primary);
    color: $primary;
    &:hover {
      background: $primary;
      color: $white !important;
    }
  }
  &.btn-light-secondary {
    background: rgba(71, 95, 123, 0.12);
    color: $secondary;
    &:hover {
      background: $secondary;
      color: $white !important;
    }
  }

  &.btn-transparent {
    background-color: transparent;
    &.btn-primary {
      color: $primary;
    }
    &.btn-secondary {
      color: $secondary;
    }
    &.btn-success {
      color: $success;
    }
    &.btn-info {
      color: $info;
    }
    &.btn-warning {
      color: $warning;
    }
    &.btn-danger {
      color: $danger;
    }
  }

  &.btn-small {
    min-width: 3rem;
  }
}

hr {
  border-top: 1px solid var(--input-border);
}
// Form Control
textarea {
  resize: none;
}
.form-control {
  background: var(--input-bg);
  color: var(--input-color);
  border: 1px solid var(--input-border) !important;
  @include placeholder {
    color: var(--gray-dark-shade1);
    font-size: $font-size-base;
    font-family: $font-family-base;
    font-weight: $font-regular;
  }
  &:read-only {
    background: var(--input-bg);
    color: var(--input-color) !important;
  }
  // &.form-control-sm{
  //     // Property
  // }
  &.focus,
  &:focus {
    @include placeholder {
      color: var(--gray-dark-shade1);
    }
    box-shadow: none;
    background: var(--input-bg-focus) !important;
    border-color: var(--input-border-focus) !important;
    color: var(--gray-dark-shade1);
  }
  &[readonly] {
    color: var(--gray-dark-shade1);
  }
  &:disabled {
    background: var(--input-bg) !important;
  }
}

.has-danger {
  color: $danger;
  .form-control {
    border-color: $danger;
  }
  .form-text {
    &.error-text {
      color: $danger;
    }
  }
}
.has-success {
  color: $success;
  .form-control {
    border-color: $success;
  }
  .form-text {
    &.success-text {
      color: $success;
    }
  }
}
.has-active {
  color: $primary;
  .form-control {
    border-color: $primary;
  }
  .form-text {
    &.active-text {
      color: $primary;
    }
  }
}

.custom-file {
  &.add {
    .custom-file-input {
      cursor: pointer;
      &:lang(en) {
        ~ .custom-file-label {
          height: calc(1.25em + 1rem + 6px);
          background: var(--input-bg);
          border-color: var(--input-border);
          &:focus {
            outline: none;
          }
          &::after {
            content: "\ebc0";
            font-family: $font-boxicon;
            background-color: $primary;
            color: $white;
            height: calc(1.25em + 1.25rem);
            padding: 0.6rem 0.75rem;
            cursor: pointer;
          }
        }
      }
    }
    .custom-file-label {
      height: calc(1.25em + 1rem + 6px);
      background: var(--input-bg);
      border-color: var(--input-border);
      z-index: 0;
      line-height: 18px;
      &:focus {
        outline: none;
      }
      &::after {
        content: "\ee23";
        font-family: $font-boxicon;
        background-color: $primary;
        color: $white;
        height: calc(1.25em + 1.25rem);
        padding: 0.6rem 0.75rem;
        cursor: pointer;
      }
    }
  }
}
/*==Custom Radio & Checkbox ==*/
.custom-checkbox {
  &.vertical {
    label {
      display: block;
    }
  }
  &.sm {
    input[type="checkbox"] {
      + span {
        content: "";
        display: inline-block;
        width: size(16);
        height: size(16);
      }
      &:checked {
        ~ span:after {
          margin-left: size(-2);
          margin-top: size(-6);
          width: size(5);
          height: size(9);
        }
      }
    }
  }
  &.rounded-checkbox {
    input[type="checkbox"] {
      display: none;
      + span {
        content: "";
        display: inline-block;
        width: size(18);
        height: size(18);
        position: absolute;
        left: 0;
        background: $white;
        border: 1px solid var(--input-border);
        border-radius: 50%;
      }

      &:checked {
        + span {
          background: $white;
          @include css3-prefix(transition, all 0.3s);
          border: 1px solid var(--input-border);
          &.bx-shadow {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          }
        }
        ~ span:after {
          top: 50%;
          left: 50%;
          margin-left: size(-4);
          margin-top: size(-4);
          width: size(8);
          height: size(8);
          display: block;
          background: $primary;
          @include border-radius(50%);
          border: none;
        }
      }
    }
  }
  label {
    margin: 0 10px 10px 0;
    padding: 0 0 0 26px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 0.75rem !important;
    line-height: 1.1rem !important;
    color: $gray-light !important;
    text-transform: none !important;
    font-weight: $font-regular !important;
    vertical-align: top;
    &.status-switch {
      margin-bottom: 0;
      width: 38px;
      height: 38px;
    }
  }
  input[type="checkbox"] {
    display: none;
    + span {
      content: "";
      display: inline-block;
      width: size(20);
      height: size(20);
      position: absolute;
      left: 0;
      background: var(--input-bg-placeholder);
      border: 1px solid var(--input-border);
      border-radius: $border-radius;
      &.switch {
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        background: var(--input-bg);
        border: 1px solid var(--input-border);
        height: 38px;
        width: 38px;
        .bx {
          color: var(--text-color);
          font-size: 1rem;
        }
      }
    }
    &:checked {
      + span {
        border: 1px solid var(--input-border);
        background: var(--input-bg-placeholder);
        @include css3-prefix(transition, all 0.3s);
        &.bx-shadow {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }
        &.switch {
          background: $primary;
          .bx {
            color: $white;
          }
        }
      }
      ~ span:after {
        top: 50%;
        left: 50%;
        margin-left: size(-2);
        margin-top: size(-7);
        width: size(5);
        height: size(10);
        border-width: 0 2px 2px 0 !important;
        display: block;
        border: solid $primary;
        @include css3-prefix(transform, rotate(45deg));
      }
      ~ .switch:after {
        border: none;
      }
    }
    ~ span:after {
      display: none;
      position: absolute;
      content: "";
    }
    &:disabled {
      + span {
        opacity: 0.7;
      }
    }
  }
}
label {
  font-size: $label-font-size;
  text-transform: uppercase;
  color: var(--text-color);
  font-weight: $font-medium;
  font-family: $font-family-base;
  line-height: $font-size-base + 0.3rem;
  margin-bottom: 0.2rem;
}
.custom-radio {
  &.vertical {
    label {
      display: block;
    }
  }
  &.sqaure-radio {
    input[type="radio"] {
      display: none;
      + span {
        content: "";
        display: inline-block;
        width: size(20);
        height: size(20);
        position: absolute;
        left: 0;
        background: var(--input-bg);
        border: 1px solid var(--input-border);
        border-radius: $border-radius;
      }
      &:checked {
        + span {
          border: 1px solid var(--input-border);
          background: var(--input-bg);
          @include css3-prefix(transition, all 0.3s);
          &.bx-shadow {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          }
        }
        ~ span:after {
          top: 50%;
          left: 50%;
          margin-left: size(-2);
          margin-top: size(-7);
          width: size(5);
          height: size(10);
          border-width: 0 2px 2px 0 !important;
          display: block;
          border: solid $primary;
          @include css3-prefix(transform, rotate(45deg));
          border-radius: 0;
          background: transparent;
        }
      }
    }
  }
  label {
    margin: 0 10px 10px 0;
    padding: 0 0 0 26px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-transform: capitalize;
  }
  input[type="radio"] {
    display: none;
    + span {
      content: "";
      display: inline-block;
      width: size(18);
      height: size(18);
      position: absolute;
      left: 0;
      background: var(--input-bg);
      border: 1px solid var(--input-border);
      border-radius: 50%;
    }
    ~ span:after {
      display: none;
      position: absolute;
      content: "";
    }
    &:disabled {
      + span {
        opacity: 0.7;
      }
    }
    &:checked {
      + span {
        background: var(--input-bg);
        border: 1px solid var(--input-border);
        @include css3-prefix(transition, all 0.3s);
        &.bx-shadow {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }
      }
      ~ span:after {
        top: 50%;
        left: 50%;
        margin-left: size(-4);
        margin-top: size(-4);
        width: size(8);
        height: size(8);
        display: block;
        background: $primary;
        @include border-radius(50%);

        .green & {
          background: $success;
        }
        .red & {
          background: $danger;
        }
      }
    }
  }
}
.ie8 .custom-checkbox input[type="checkbox"],
.ie8 .custom-radio input[type="radio"] {
  display: inline-block;
}
.ie8 .custom-checkbox span,
.ie8 .custom-radio span {
  display: none;
}

.form-group {
  // label{
  //     font-size: $label-font-size;
  //     text-transform: uppercase;
  //     color: var(--text-color);
  //     font-weight: $font-medium;
  //     font-family: $font-family-base;
  //     line-height: $font-size-base + 0.3rem;
  //     margin-bottom: 0.2rem;
  // }
  .form-text {
    font-size: $font-size-base - 0.3rem;
    color: $dark;
    font-family: $font-heading;
    font-weight: $font-medium;
  }
}

.dropdown {
  .btn {
    position: relative;
    &.dropdown-btn {
      padding: size(4) size(30) size(4) size(20);
      border: 1px solid var(--input-border);
      font-size: $font-family-base;
      line-height: 1.3 rem;
      color: $gray-dark;
      background: var(--input-bg);
      .color-box {
        background: $success;
        border: 1px solid $border-shade1;
        display: inline-block;
        vertical-align: text-top;
        width: 100%;
        height: 20px;
        border-radius: 0.25rem;
      }
      .drp-icon {
        width: 24px;
        height: 24px;
        background: $success;
        color: var(--white);
        border-radius: 50%;
        font-size: 0.75rem;
        letter-spacing: 0.36px;
        line-height: 11px;
        font-family: $font-heading;
        font-weight: $font-medium;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.62rem;
      }
    }
    &.dropdown-toggle {
      &::after {
        content: "\e9ac";
        border: 0;
        font-family: $font-boxicon;
        position: absolute;
        right: size(8);
        top: size(5);
        color: $gray;
      }
    }
    &:focus {
      box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.25) !important;
    }
    .icon-btn {
      font-size: 1.25rem;
      vertical-align: text-top;
    }
  }
  .dropdown-menu {
    position: relative;
    top: 0.5rem !important;
    box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
    background: var(--input-bg);
    border-color: var(--input-border);
    &::before {
      border-top: 1px solid var(--input-border);
      border-left: 1px solid var(--input-border);
      content: "";
      position: absolute;
      top: -1px;
      right: 1.2rem;
      width: 0.75rem;
      height: 0.75rem;
      display: block;
      background: var(--card-bg);
      transform: rotate(45deg) translate(-7px);
      z-index: -1;
      box-sizing: border-box;
    }
    &.dropdown-icon {
      .dropdown-item {
        display: flex;
        align-items: center;
        padding: 0.62rem 1.3rem;
        color: var(--text-color);
        &:active {
          background: var(--light) !important;
          color: var(--text-color);
        }
        &:hover {
          background: var(--light) !important;
          color: var(--text-color);
        }
        &:focus {
          background: var(--light);
          color: var(--text-color);
        }
        .bx {
          color: var(--dark);
        }
        span {
          font-size: 16px;
          line-height: 1.5rem;
          color: var(--dark);
          font-family: $font-family-base;
          font-weight: $font-regular;
          text-transform: uppercase;
        }
        .color-shade {
          width: 1.25rem;
          height: 1.25rem;
          display: inline-block;
          border-radius: 0.25rem;
          margin-right: 0.625rem;
          &.bg-yellow {
            background: $warning;
          }
          &.bg-green {
            background: $success;
          }
          &.bg-purple {
            background: $purple;
          }
          &.bg-rose {
            background: #eb2f6b;
          }
        }
        .color-text {
          font-size: 0.75rem;
          text-transform: capitalize;
          color: $gray-light;
          line-height: 1.25rem;
        }
      }
      .storage {
        padding: 0.75rem 1rem;
        background: $light-shade;
        .storage-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.3rem;
          span {
            display: block;
          }
          .text {
            font-size: 1rem;
            color: $dark;
            font-family: $font-family-base;
            font-weight: $font-medium;
            width: calc(100% - 50px);
          }
          .text-blue {
            font-size: 0.75rem;
            color: $primary;
            font-family: $font-family-base;
            font-weight: $font-regular;
            width: size(50);
            text-align: right;
          }
        }
        .progress {
          height: 0.25rem;
        }
      }
    }
  }
  .dropdown-toggle {
    &.nav-link {
      &:after {
        display: none;
      }
    }
  }
}

.breadcrumb {
  align-items: center;
  background: $light;
  &.rounded {
    border-radius: size(27) !important;
    background: $white;
    box-shadow: 3px 3px 14px 0 rgba(0, 0, 0, 0.12);
  }
  .breadcrumb-item {
    font-size: $font-size-base;
    a {
      color: var(--gray-dark-shade1);
      text-decoration: none;
      &:hover {
        color: $primary;
      }
    }
    .bx {
      font-size: $font-size-base + 0.5rem;
      padding-top: 3px;
    }
    &.active {
      color: $primary;
    }
    + .breadcrumb-item {
      &::before {
        content: "\ea50" !important;
        font-family: $font-boxicon;
      }
    }
  }
}

.badge {
  padding: size(5) size(20);
  font-size: 0.75rem;
  &.badge.badge-light-primary {
    background-color: #e2ecff;
    color: #5a8dee !important;
  }
  &.badge-light-secondary {
    background-color: #e6eaee;
    color: #475f7b !important;
  }
  &.badge-light-success {
    background-color: #d2ffe8;
    color: #39da8a !important;
  }
  &.badge-light-info {
    background-color: #ccf5f8;
    color: #00cfdd !important;
  }
  &.badge-light-warning {
    background-color: #ffeed9;
    color: #fdac41 !important;
  }
  &.badge-light-danger {
    background-color: #ffdede;
    color: #ff5b5c !important;
  }

  // <span className="badge badge-light-success badge-pill">PAID</span>
}

.alert {
  &.badge-success {
    background: var(--alert-bg-success);
    color: var(--alert-color-success);
  }
  &.badge-warning {
    background: var(--alert-bg-warning);
    color: var(--alert-color-warning);
  }
  &.badge-danger {
    background: var(--alert-bg-danger);
    color: var(--alert-color-danger);
  }
}

// full circle badge as radio buttn with text on side
.badge-outer {
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid var(--border-color);
  justify-content: center;
  align-items: center;
  padding: 0.32rem 0.1125rem;
  .badge {
    display: flex;
    width: 8px;
    height: 8px;
    padding: 0;
  }
}

.custom-switch {
  padding-left: 3.5rem;
  &.light {
    .custom-control-label {
      &::before {
        background: var(--switch-bg);
        border-color: var(--switch-border);
      }
      &::after {
        background: $white;
      }
    }
    .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          &::before {
            background: $primary;
            border-color: $primary;
          }
        }
      }
    }
  }
  .custom-control-input {
    width: 2.75rem;
    left: 0.25rem;
    &:checked {
      ~ .custom-control-label {
        &::after {
          transform: translateX(1.5rem);
        }
      }
    }
  }
  .custom-control-label {
    &::before {
      left: -3.25rem;
      width: 2.75rem;
      height: 1.25rem;
      border-radius: 0.75rem;
      top: 0;
    }
    &::after {
      top: calc(0.125rem + 1px);
      left: calc(-3.25rem + 3px);
      width: calc(1rem - 2px);
      height: calc(1rem - 2px);
    }
  }
}
a {
  color: $primary;
  text-decoration: none;
  &.card-link {
    font-size: 0.75rem;
    line-height: 1rem;
    color: $primary;
    font-weight: $font-regular;
    &:hover {
      text-decoration: none;
      color: darken($primary, 50%);
    }
  }
  small {
    color: $primary;
  }
  &:hover {
    text-decoration: none;
    color: darken($primary, 50%);
    [data-theme="dark"] & {
      color: darken($primary, 10%);
    }
  }
}

.form-group {
  .input-group-prepend {
    .custom-select {
      &.nice-select {
        min-width: 4.5rem;
        width: 5.5rem;
        &.open {
          .list {
            width: 100%;
          }
        }
      }
    }
    .btn {
      min-width: 5rem;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      &.btn-outline-secondary {
        color: $dark;
        border-color: var(--input-border);
        &:hover {
          background: transparent;
        }
        &:focus {
          box-shadow: none;
        }
        &.dropdown-toggle {
          background: transparent;
          &::after {
            content: "\e9ac";
            border: 0;
            font-family: $font-boxicon;
            position: absolute;
            right: size(0);
            top: size(3);
            color: $gray;
          }
        }
      }
    }
    .dropdown-menu {
      min-width: 5rem !important;
      .dropdown-item {
        padding: 0.25rem 0.5rem;
      }
    }
  }
  .field-icon {
    position: absolute;
    right: size(12);
    top: size(33);
    font-size: 1.25rem;
    color: var(--text-color);
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
  }
  .field-icon-profile {
    position: absolute;
    right: size(10);
    top: size(10);
    font-size: 1.25rem;
    color: var(--text-color);
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
  }
  .field-icon-label {
    position: absolute;
    right: size(12);
    top: size(10);
    font-size: 1.25rem;
    color: var(--text-color);
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
  }
}

.otSc {
  margin: 0;
  margin-right: 16px;
  border: 1px solid;
  padding: 10px 0px;
  font-size: 38px;
  text-align: center;
  width: 100%;
  outline: none;
}
.otSc:last-child {
  margin-right: 0;
}
.otpCont {
  padding: 40px 40px;
}

.selectpicker {
  .btn {
    display: block;
    width: 100%;
    height: calc(1.25em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #495057;
    background: var(--input-bg);
  }
}

/* picker  */

.picker {
  .picker__holder {
    border-radius: 0.267rem;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    background: var(--bg-white);
    border-color: var(--input-border);
    &:focus {
      outline: none;
    }
    .picker__table {
      tr {
        td {
          color: var(--dark);
        }
      }
    }
    .picker__select--month {
      background: var(--card-bg);
      color: var(--dark);
      border-color: var(--input-border);
    }
    .picker__select--year {
      background: var(--card-bg);
      color: var(--dark);
      border-color: var(--input-border);
    }
    .picker__footer button {
      background: var(--card-bg);
      color: var(--dark);
    }
    .picker__day--today {
      background: $primary;
      color: var(--text-color);
      &:hover {
        background: var(--login-card-bg);
        color: var(--text-color);
      }
    }
    .picker__day--infocus {
      &:hover {
        background: var(--login-card-bg);
        color: var(--text-color);
      }
    }
    .picker__day--outfocus {
      &:hover {
        background: var(--login-card-bg);
        color: var(--dark);
      }
    }
    .picker__day--highlighted {
      background: $primary;
    }
    .picker__button--close {
      &::before {
        content: "\ec4e";
        font-family: $font-boxicon;
      }
    }
  }
  .picker__nav--prev {
    &::before {
      content: "\ea1a";
      font-family: $font-boxicon;
      color: var(--dark);
    }
    &:hover {
      background: var(--login-card-bg);
    }
  }
  .picker__nav--next {
    &::before {
      content: "\ea1d";
      font-family: $font-boxicon;
      color: var(--dark);
    }
    &:hover {
      background: var(--login-card-bg);
    }
  }
}

.has-icon-left {
  .form-control {
    padding-left: 2.5rem;
    padding-right: 1.5rem;
    text-transform: uppercase;
  }
  .form-control-position {
    position: absolute;
    top: size(10);
    left: size(10);
    color: $gray;
  }
}
.has-icon-right {
  .form-control {
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    text-transform: uppercase;
  }
  .form-control-position {
    position: absolute;
    top: size(10);
    right: size(10);
    color: $gray;
  }
}

.bootstrap-select {
  .dropdown-menu {
    li {
      &:hover {
        .dropdown-item {
          background: $light !important;
          color: var(--text-color);
          &.active {
            background: var(--light) !important;
            color: var(--text-color);
          }
        }
      }
    }
  }
}

.pagination {
  .page-link {
    background: var(--card-bg);
    border-color: var(--input-border);
  }
}

/* daterangepiker */
.daterangepicker {
  background: var(--bg-white);
  border-color: var(--input-border);
  .calendar-table {
    background: var(--bg-white);
    border-color: transparent;
    .next {
      &:hover {
        background: var(--card-bg);
      }
      span {
        border-color: var(--text-color);
      }
    }
    .prev {
      &:hover {
        background: var(--card-bg);
      }
      span {
        border-color: var(--text-color);
      }
    }
  }
  td {
    &.off {
      background: transparent;
      color: var(--dark) !important;
    }
    &.available {
      color: var(--text-color);
      &:hover {
        background: var(--login-card-bg);
        color: var(--text-color);
      }
    }
    &.active {
      background: $primary;
      color: $white;
    }
  }
  th {
    color: var(--text-color);
  }
  .drp-buttons {
    border-color: var(--input-border);
    .drp-selected {
      color: var(--dark);
    }
  }
  &::after {
    border-bottom: 6px solid var(--bg-white);
  }
  .calendar-time {
    color: var(--dark);
    select {
      &.ampmselect {
        background: var(--login-card-bg);
        color: var(--dark);
        border-color: var(--input-border);
      }
      &.minuteselect {
        background: var(--login-card-bg);
        color: var(--dark);
        border-color: var(--input-border);
      }
      &.hourselect {
        background: var(--login-card-bg);
        color: var(--dark);
        border-color: var(--input-border);
      }
    }
  }
}

.dropdown-toggle {
  &::after {
    content: "\e9ac";
    border: 0;
    font-family: $font-boxicon;
    color: $gray;
    vertical-align: top;
  }
}
.custom-dropdown {
  margin: 0 5px;
  &.open {
    > .btn {
      position: relative;
      &:before {
        visibility: visible;
      }
    }
    .custom-dropdown-menu {
      display: block;
    }
  }
  .dropdown-toggle {
    padding: 0.25rem 1.5rem 0.25rem 0.625rem;
    &:after {
      content: "\e9ac";
      border: 0;
      font-family: $font-boxicon;
      position: absolute;
      right: size(8);
      top: size(5);
      color: var(--dark);
    }
  }
  > .btn {
    position: relative;
    &:before {
      visibility: hidden;
      top: auto;
      border-top: 1px solid var(--input-border);
      border-left: 1px solid var(--input-border);
      content: "";
      position: absolute;
      bottom: -14px;
      right: 50%;
      width: 0.75rem;
      height: 0.75rem;
      display: block;
      background: var(--card-bg);
      transform: rotate(45deg) translate(-7px);
      z-index: 1031;
      box-sizing: border-box;
      @include css3-prefix(transition, all 0s cubic-bezier(0.25, 0.8, 0.25, 1))
        /* background: red; */;
    }
  }
  .custom-dropdown-menu {
    width: 100%;
    color: var(--dt-text-color);
    //top: 3rem !important;
    position: absolute;
    // top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 1rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards
      1 fadein;
    animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    border-radius: 5px;
    box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
    background: var(--input-bg);
    background-clip: padding-box;
    // z-index: 1;
    display: none;
    [data-theme="dark"] & {
      border: 1px solid var(--input-border);
    }
  }
}

.colorbox {
  .dropdown-menu {
    position: absolute;
    top: 100% !important;
  }
}

.custome-dropdown {
  .custome-btn {
    background: transparent;
    min-width: 100%;
    padding: 0;
    border: 0;
    &::after {
      content: none;
    }
  }
  &.show {
    .custome-btn {
      background: transparent;
      border: 0;
    }
  }
  &:focus {
    outline: none;
  }

  .btn {
    &.dropdown-toggle {
      &::after {
        content: "";
      }
      &:active {
        background: transparent;
      }
    }
  }
  .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    top: 100% !important;
    .dropdown-item {
      &.dropdown-menu-header {
        padding: 0;
      }
    }
  }
}

.react-tel-input {
  .form-control {
    width: 100% !important;
  }
}

.language-dropdown {
  .custom-select {
    background: transparent;
    position: relative;
    padding-right: 0.25rem;
    cursor: pointer;
    color: var(--text-color);
    &:focus {
      box-shadow: none;
    }
    // &:after{
    //     content: "\ecee";
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     display: inline-block;
    //     font-size: $font-size-base;
    //     font-family: $font-boxicon;
    // }
  }
}

.react-tel-input {
  .form-control {
    background: var(--input-bg);
    height: 38px;
  }
  .flag-dropdown {
    background: var(--input-bg);
    border-color: var(--input-border);
    .selected-flag {
      &:hover {
        background: var(--light);
      }
    }
    &.open {
      background: var(--input-bg);
      border-color: var(--input-border);
      .selected-flag {
        background: var(--input-bg);
      }
    }
    .country-list {
      background: var(--input-bg);
      .country-name {
        color: var(--text-color);
      }
      .country {
        &:hover {
          background: var(--light);
        }
        &.highlight {
          background: var(--light);
        }
      }
    }
  }
}

label {
  sup {
    font-size: 0.75rem;
    top: -2px;
  }
}

.react-datepicker {
  background: var(--card-bg);
  border: 1px solid var(--input-border);
}
.react-datepicker__header {
  background: var(--light);
  border: 1px solid var(--input-border);
}
.react-datepicker__navigation--previous {
  border-right-color: var(--text-color);
}
.react-datepicker__navigation--next {
  border-left-color: var(--text-color);
}
.react-datepicker__current-month {
  color: var(--text-color);
}
.react-datepicker__day-name {
  color: var(--text-color);
}
.react-datepicker__day {
  color: var(--text-color);
  &:hover {
    background: var(--light);
  }
}
.react-datepicker-popper[data-placement^="top"] {
  .react-datepicker__triangle {
    border-top-color: var(--text-color);
  }
}
.react-datepicker-popper {
  z-index: 3;
}
.selectpicker {
  .css-yk16xz-control {
    background-color: var(--input-bg);
    border-color: var(--input-border);
    &:hover {
      border-color: $primary;
    }
    .css-1uccc91-singleValue {
      color: var(--text-color);
    }
  }
  .css-26l3qy-menu {
    background-color: var(--input-bg);
    border: 1px solid var(--input-border);
    .css-yt9ioa-option {
      color: var(--text-color);
    }
    .css-4ljt47-MenuList {
      max-height: 120px !important;
    }
  }
  .css-1pahdxg-control {
    background-color: var(--input-bg);
    color: var(--text-color);
    .css-1uccc91-singleValue {
      color: var(--text-color);
    }
  }
}

.user-listing {
  .right-btns {
    .btn {
      margin: 0 5px;
    }
  }
  .left-btns {
    .btn {
      margin: 0 5px;
    }
  }
}

.css-yk16xz-control {
  background: var(--input-bg);
  .css-1wa3eu0-placeholder {
    color: var(--gray-dark-shade1);
    font-size: $font-size-base;
    font-family: $font-family-base;
    font-weight: $font-regular;
  }
}

.custom-input {
  border-radius: 4px !important;
  .ant-select-selector {
    height: 38px !important;
    border-radius: 4px !important;

    .ant-select-selection-item {
      line-height: 38px !important;
      color: var(--text-color);
      font-size: $font-size-base;
      font-family: $font-family-base;
      font-weight: $font-regular;
    }
    .ant-select-selection-search-input {
      height: 38px !important;
    }
  }
  .ant-select-single {
    .ant-select-selector {
      .ant-select-selection-item {
        line-height: 38px !important;
      }
    }
  }
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: var(--input-bg);
  border: 1px solid var(--input-border);
}
.ant-select-dropdown {
  background: var(--card-bg);
  color: var(--text-color);
  border: 1px solid var(--input-border);
  .ant-select-item {
    color: var(--text-color);

    &:hover {
      background-color: var(--light);
    }
    &.ant-select-item-option-selected {
      background: var(--input-bg);
      &:hover {
        background-color: var(--light);
      }
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--light);
  color: var(--text-color);
}
.ck.ck-toolbar {
  background: var(--light);
}
.ck.ck-editor__main > .ck-editor__editable {
  background: var(--input-bg);
  color: var(--text-color);
}
.ck.ck-button {
  color: var(--text-color) !important;
}
.ck.ck-button:not(.ck-disabled):hover {
  background: var(--light);
}

.img-upload-square {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5a8dee;
  width: 39px;
  height: 39px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  .bx {
    position: absolute;
    right: 0;
    top: 0;
  }
  &.sm {
    width: 25px;
    height: 25px;
    .bx {
      font-size: 1rem;
    }
  }
}
.custom-select-image {
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.caption-data {
  background: var(--input-bg);
  color: var(--text-color);
  border-radius: 4px;
  padding: 10px;
  border: 1px solid var(--input-border);
  height: 250px;
  overflow-y: auto;
  strong {
    font-weight: bolder !important;
    font-family: $font-heading;
  }
  ul {
    list-style: disc !important;
    padding-left: 1rem;
    li {
      list-style: disc !important;
    }
  }
  ol {
    list-style: decimal !important;
    padding-left: 1rem;
    li {
      list-style: decimal !important;
    }
  }
}
.img-space {
  width: 100%;
  height: 150px;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  background: #979797;
  overflow: hidden;
}
.product-img {
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #fff;
  }
}

.react-datepicker__day--selected {
  background: $primary;
  color: var(--white);
  &:hover {
    color: var(--text-color);
  }
}

.custom-daterange {
  .react-datepicker-wrapper {
    display: block;
  }
}

.ant-select-dropdown {
  .dropdown-item {
    padding: 0;
    display: flex;
    align-items: center;
    .color-shade {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      border-radius: 0.25rem;
      margin-right: 0.625rem;
      &.bg-yellow {
        background: $warning;
      }
      &.bg-green {
        background: $success;
      }
      &.bg-purple {
        background: $purple;
      }
      &.bg-rose {
        background: $danger;
      }
      &.bg-gray {
        background: $gray;
      }
      &.bg-skyblue {
        background: $info;
      }
      &.bg-red {
        background: $danger;
      }
    }
    .color-text {
      font-size: 0.75rem;
      text-transform: capitalize;
      color: $gray-light;
      line-height: 1.25rem;
    }
  }
}
.ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .dropdown-item {
    padding: 0;
    display: flex;
    align-items: center;
    .color-shade {
      border: 1px solid #979797;
      display: inline-block;
      vertical-align: text-top;
      width: 100%;
      height: 20px;
      border-radius: 0.25rem;
      &.bg-yellow {
        background: $warning;
      }
      &.bg-green {
        background: $success;
      }
      &.bg-purple {
        background: $purple;
      }
      &.bg-rose {
        background: $danger;
      }
      &.bg-gray {
        background: $gray;
      }
      &.bg-skyblue {
        background: $info;
      }
      &.bg-red {
        background: $danger;
      }
    }
    .color-text {
      font-size: 0;
      // text-transform: capitalize;
      // color:$gray-light;
      // line-height: 1.25rem;
    }
  }
}

.filter-label {
  text-transform: capitalize;
  color: var(--text-color);
  font-weight: 600;
  width: 7.5rem;
}

.filter-select {
  .ant-select-selection-item {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.ant-select-selection-placeholder {
  color: var(--gray-dark-shade1);
  font-size: $font-size-base;
  font-family: $font-family-base;
  font-weight: $font-regular;
  line-height: 36px;
}

.list-group-item {
  .custom-checkbox {
    label {
      word-break: break-all;
    }
  }
}

.rc-pagination-prev {
  .rc-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
}

.input-code {
  background: var(--input-bg);
  color: var(--input-color);
  border: 1px solid var(--input-border) !important;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: var(--light);
}

.ck {
  &.ck-button {
    &.ck-on {
      background: var(--light-shade);
      &:hover {
        background: var(--light-shade);
      }
    }
  }
}
.ck.ck-button.ck-on:not(.ck-disabled):hover {
  background: var(--light-shade);
}
.ck.ck-link-form {
  background: var(--input-bg);
}
.ck.ck-input-text {
  background: var(--input-bg);
}
.ck.ck-input-text:focus {
  border: none;
}
.ck.ck-dropdown__panel {
  background: var(--input-bg);
}
.ck.ck-list {
  background: var(--input-bg);
}
.ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background: var(--light);
}

.clear {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  color: #b3c0ce;
}

.error-message-password {
  width: calc(100% - 80px);
  margin: -15px 0 0 auto;
  &.class1 {
    justify-content: flex-start !important;
  }
}

.rdtPicker {
  background: var(--input-bg);
  border-color: var(--input-border);
  table {
    thead {
      tr {
        th {
          color: var(--text-color);
          border-bottom: 1px solid var(--input-border);
        }
        &:first-of-type {
          th {
            &:hover {
              background: var(--light);
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &.rdtOld {
            color: var(--dark);
          }
          &.rdtNew {
            color: var(--dark);
          }
          &.rdtDay {
            color: var(--text-color);
            &:hover {
              background: var(--light);
            }
          }
        }
      }
    }
    tfoot {
      border-top: 1px solid var(--input-border);
      tr {
        td {
          &.rdtTimeToggle {
            color: var(--text-color);
            &:hover {
              background: var(--light);
            }
          }
        }
      }
    }
  }
}

.rdtTime {
  table {
    thead {
      tr {
        td {
          cursor: pointer;
          color: var(--text-color);
        }
      }
    }
    tbody {
      tr {
        td {
          cursor: pointer;
          color: var(--text-color);
          .rdtCounter {
            .rdtBtn {
              &:hover {
                background: var(--light);
              }
            }
          }
        }
      }
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999;
}

.sun-editor .se-toolbar {
  background: var(--light);
  outline: 1px solid var(--input-border);
}
.sun-editor {
  border: 1px solid var(--input-border);
  min-width: unset !important;
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  background-color: var(--input-bg);
  color: var(--text-color);
}
.sun-editor .se-resizing-bar {
  border-top: 1px solid var(--input-border);
  background-color: var(--input-bg);
}
.sun-editor .se-svg,
.sun-editor button > svg {
  color: var(--text-color);
}
.sun-editor .se-btn:enabled:focus,
.sun-editor .se-btn:enabled:hover {
  background-color: var(--light-shade);
  border-color: var(--light-shade);
}
.sun-editor .se-btn:enabled:active {
  background-color: var(--light-shade);
  border-color: var(--light-shade);
  box-shadow: none;
}
.sun-editor .se-btn:enabled.active:focus,
.sun-editor .se-btn:enabled.active:hover {
  background-color: var(--light-shade);
  border-color: var(--light-shade);
  box-shadow: none;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form {
  padding: 3px 4px;
  background: var(--input-bg);
  color: var(--text-color);
  border: var(--input-border);
}
.se-dialog-footer {
  & > div {
    display: flex;
    align-items: center;
    & > label {
      display: flex;
      align-items: center;
    }
  }
}
.sun-editor .se-dialog input[type="checkbox"] {
  background: var(--input-bg);
  border: 1px solid var(--input-border);
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-control {
  background: var(--light);
  color: var(--text-color);
  border: 1px solid var(--input-border);
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div > label {
  display: flex;
  align-items: center;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title {
  color: var(--text-color);
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  .se-dialog-files-edge-button {
  border: 1px solid var(--input-border);
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  .se-dialog-files-edge-button:hover {
  background: var(--light);
}
.form-group .form-text {
  color: var(--text-color);
}
.sun-editor .se-dialog-tabs button {
  padding: 4px 13px;
  background: var(--bg-white);
  color: var(--text-color);
}
.sun-editor .se-dialog-tabs {
  height: 30px;
}
.sun-editor .se-dialog-tabs button:hover {
  background: var(--light);
}
.sun-editor .se-dialog-tabs button.active {
  background: var(--light);
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content label {
  color: var(--text-color);
  font-weight: 600;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert {
  border: 1px solid var(--input-border);
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert:hover {
  background: var(--light);
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form {
  margin-top: 0.25rem;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
  background: var(--bg-white);
}
.ant-select {
  color: var(--text-color);
}

.ant-select-arrow {
  color: var(--text-color);
}

.modal-content {
  background: var(--input-bg);
}
.modal-content {
  .close {
    color: var(--text-color);
  }
  .modal-header {
    color: var(--text-color);
    .modal-title {
      color: var(--text-color);
    }
  }
}

.rdt {
  .form-control {
    cursor: pointer;
    color: var(--dark);
  }
}

.cropper-canvas {
  img {
    width: 100% !important;
    transform: none !important;
  }
}

.select-text {
  color: $white;
  background: $success;
}

// .form-repeat{
//     &:first-child{
//         .repeater-default{
//             .add-row{
//                 display: block !important;
//             }
//         }
//     }
// }.form-repeat{
//     .repeater-default{
//         .add-row{
//             display: none;
//         }
//     }
// }

.filter-category {
  position: relative;
  .filter-category-menu {
    width: 100%;
    color: var(--dt-text-color);
    top: 3rem !important;
    position: absolute;
    right: 0;
    z-index: 1000;
    float: left;
    margin: 0.125rem 0 0;
    min-width: 180px;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    border-radius: 5px;
    box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
    background: var(--input-bg);
    display: none;
    .category-list {
      .custom-checkbox {
        label {
          padding: 0 0 0 35px;
          font-size: 1rem !important;
          color: var(--text-color) !important;
          margin: 0 0 15px 0 !important;
        }
      }
    }
  }
  &.open {
    .filter-category-menu {
      display: block;
    }
  }
}

.category-search {
  .search-icon {
    position: absolute;
    left: 8px;
    top: 12px;
  }
  .form-control {
    padding: 0.5rem 0.75rem 0.5rem 1.5rem;
  }
}

.select__input {
  input {
    width: 100% !important;
  }
}
.select__value-container--has-value {
  .select__input {
    input {
      width: 2px !important;
    }
  }
}

.calendar-button {
  border-bottom: 1px solid #d8e5ee;
  .calendar-button :hover {
    cursor: pointer;
  }
  .btn {
    &.btn-light {
      min-width: inherit;
      padding: 11px 25px;
      margin: 0;
      background-color: #fafbfc;
      border-radius: 0;
      border: none;
      border-right: 1px solid #d8e5ee;
      &.active {
        background-color: #d6dddd;
        border-color: #cfd7d7;
        color: #000;
        box-shadow: none !important;
      }
      &.next {
        background: #fff;
        border: none;
        box-shadow: none !important;
      }
      &.prev {
        background: #fff;
        border: none;
        border-right: 1px solid #d8e5ee;
        box-shadow: none !important;
      }
    }
  }
}


.brodcast-btn,.addShipper-btn{
  color: var(--dark) !important;
  &:hover{
    color: $white !important;
  }
}
.attach-file{
  margin-right: 20px;
  #file{
    display: none;
  }
  span{
    display: block;
    width: 100%;
    height: calc(1.25em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: $primary;
    color: $white !important;
    box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.5) !important;
  }
}
.upload-photo{
  display: block;
  #photo{
    display: none;
  }
  #photoadd{
    display: none;
  }
  .choose-photo{
    background: var(--input-bg);
    color: var(--input-color);
    border: 1px solid var(--input-border) !important;
    width: 100%;
    height: calc(1.25em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.25;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    display: block;
  }
}


.filter-details-wrap{
  margin-bottom: 100px;
}
