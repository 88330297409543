
  // background-color: #f4f7f6;
  // margin-top: 20px;
  .chat-section{
    padding-top: 88px;
  }
//   .chat-section .card {
//   background: #fff;
//   transition: 0.5s;
//   border: 0;
//   margin-bottom: 30px;
//   border-radius: 0.55rem;
//   position: relative;
//   width: 100%;
//   box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
// }
// .chat-app {
//   padding-top: 88px;
// }
 .chat-app .people-list {
  width: 280px;
  position: absolute;
  left: 0;
  top: 88px;
  padding: 20px;
  padding-top: 0;
  z-index: 7;
  @include media(down, $breakpoint-md) {
    position: unset;
    padding-left: 0;
  }
}

.chat-app .file-input {
  display: none;
}

.chat-app .file-image {
  width: 80px;
  height: 80px;
}

.chat-app .chat {
  margin-left: 280px;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 50px;
  @include media(down, $breakpoint-md) {
    margin-left: 0;
  }
}

.people-list {
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.dialog-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
}
.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px;
  background: #f1f1f1;
  margin-bottom: 10px
}

.people-list .chat-list li:hover {
  background: #efefef;
  cursor: pointer;
}

.people-list .chat-list li.active {
  background: #efefef;
}

.people-list .chat-list li .name {
  font-size: 15px;
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%;
}

.people-list img {
  float: left;
  border-radius: 50%;
}

.people-list .about {
  float: left;
  padding-left: 8px;
}

.people-list .status {
  color: #999;
  font-size: 13px;
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6;
  border-top: 2px solid #f4f7f6;
  
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
}

.chat .chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff;
  height: 440px;
  overflow-x: hidden;
  border-right: 2px solid #f4f7f6;
  overflow-y: auto;
}
.chat-message .input-group-text{
  border: 1px solid rgba(165, 166, 182, 0.3);
}

.chat .chat-history ul {
  padding: 0;
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px;
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
  width: 50%;
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
  
}

.chat .chat-history .my-message {
  background: #efefef;
}

.chat .chat-history .my-message:after {
  bottom: 100%;
  right: 30px;
  left: unset;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .other-message {
  background: #e8f1f3;
  text-align: right;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left:30px;
}

.chat .chat-message {
  padding: 20px;
  border-top: 2px solid #eaeaea;
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 8px;
  vertical-align: middle;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

@media only screen and (max-width: 767px) {
  // .chat-app .people-list {
  //   height: 465px;
  //   width: 100%;
  //   overflow-x: auto;
  //   background: #fff;
  //   left: -400px;
  //   display: none;
  // }
  .chat-app .people-list.open {
    left: 0;
  }
  .chat-app .chat {
    margin: 0;
  }
  .chat-app .chat .chat-header {
    border-radius: 0.55rem 0.55rem 0 0;
  }
  .chat-app .chat-history {
    height: 300px;
    overflow-x: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
    height: 650px;
    overflow-x: auto;
  }
  .chat-app .chat-history {
    height: 600px;
    overflow-x: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
    height: 480px;
    overflow-x: auto;
  }
  .chat-app .chat-history {
    height: calc(100vh - 350px);
    overflow-x: auto;
  }
}
