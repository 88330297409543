.card {
  background: var(--bg-white);
  border: none;
  padding: 2rem;
  @include media(down, $breakpoint-md) {
    padding: 1rem;
  }
}
.alert {
  padding: 0.25rem;
  font-size: 0.75rem;
  &.alert-primary {
    background: $primary;
    color: var(--white);
  }
}

.setting-dropdown {
  em {
    font-size: size(26);
  }
  .dark-icon {
    svg {
      fill: #000;
    }
  }
  .dropdown-menu {
    min-width: 5rem;
  }
}

.nav-pagination {
  display: flex;
  .page-item {
    .page-link {
      display: inline-block;
      vertical-align: middle;
      color: var(--text-color);
      background: var(--bg-white);
      border-color: var(--input-border);
      border-left: 0;
      border-right: 0;
      &:hover {
        background: $primary;
        color: $white;
        border-radius: 0.25rem;
      }
      &:focus {
        box-shadow: none;
      }
    }
    &:first-child {
      .page-link {
        border-left: 1px solid var(--input-border) !important;
      }
    }
    &:last-child {
      .page-link {
        border-right: 1px solid var(--input-border) !important;
      }
    }
    &.active {
      .page-link {
        background: $primary;
        color: $white;
        border-radius: 0.25rem;
      }
    }
  }
  .left-side {
    margin-right: 0.4rem;
    .previous {
      border-radius: 0.25rem;
      border-left: 1px;
      border-right: 1px;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      border: 1px solid var(--input-border);
      display: inline-block;
      color: var(--text-color);
      background: var(--bg-white);
      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }
  .right-side {
    margin-left: 0.4rem;
    .next {
      border-radius: 0.25rem;
      border-left: 1px;
      border-right: 1px;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      border: 1px solid var(--input-border);
      display: inline-block;
      color: var(--text-color);
      background: var(--bg-white);
      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }
}
.dropdown {
  .btn {
    &:focus {
      box-shadow: none !important;
    }
  }
}
.comming-soon {
  padding: 100px 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background: var(--soon-bg);
  max-width: 500px;
  border: 1px solid var(--input-border);
  h2 {
    color: var(--white);
  }
}

.accordion {
  .card {
    .card-header {
      padding: 1.8rem 1.8rem;
      background: var(--light-shade-1);
      .btn {
        &:focus {
          box-shadow: none;
        }
      }
      .icon {
        font-size: 1.5rem;
        line-height: 24px;
      }
    }
    .card-body {
      background: var(--light-shade-1);
      border-top: 1px solid var(--main-border);
    }
  }
  .custom-accordian {
    &.disable {
      .card-header {
        background: var(--light);
      }
      .card-body {
        background: var(--light);
      }
    }
  }
}

.ReactModal__Content {
  &.ReactModal__Content--after-open {
    max-width: 700px;
    margin: 0 auto;
  }
}

.user-listing-filterOptions {
  .left-buttons {
    .delete-btn {
      margin: 0px 5px;
      min-width: unset;
      height: 38px;
    }
    .switch-btn {
      margin: 0px 5px;
      min-width: unset;
      height: 38px;
    }
    .search-input-wrapper {
      input[type="search"] {
        padding-left: 30px;
        margin-left: 0;
      }
      .bx {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        font-size: 0.875rem;
      }
    }
  }
  .right-buttons {
    .btn {
      margin: 0 5px;
    }
    .input-group-append {
      .btn {
        margin: 0;
      }
    }
  }
  .download-dropdown {
    .download-dropdown-menu {
      width: 100%;
      color: var(--dt-text-color);
      top: 3rem !important;
      position: absolute;
      right: 0;
      z-index: 1000;
      float: left;
      min-width: 660px;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      text-align: left;
      list-style: none;
      border: 1px solid rgba(0, 0, 0, 0.15);
      animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
        fadein;
      border-radius: 5px;
      box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
      background: var(--input-bg);
      background-clip: padding-box;
      display: none;

      .left-col {
        flex: 0 0 20%;
        min-width: 175px;
        height: 350px;
        overflow-y: auto;
        .list-group-item {
          border: none;
          border-radius: 0;
          align-items: center;
          padding: 15px 10px 15px 20px;
          background: var(--input-bg);
          .handle {
            margin-right: 10px;
          }
          .custom-checkbox {
            label {
              margin: 0;
              font-size: 0.875rem !important;
              line-height: 20px !important;
              padding-left: 38px;
              color: var(--input-color) !important;
              font-weight: 600 !important;
            }
          }
        }
      }
      .right-col {
        .col-left {
          flex: 0 0 65%;
          margin-right: 1rem;
        }
        .col-right {
          flex: 0 0 auto;
          .right-col-list {
            max-height: 200px;
            overflow: auto;
          }
        }
      }
      .template-list-outer {
        height: 220px;
        overflow-y: auto;

        .template-list {
          .list-group-item {
            padding-top: 9px;
            padding-bottom: 9px;
            border: 0;
          }
        }
      }
      .bottom-buttons {
        button {
          min-width: 90px;
          color: var(--text-color);
          border-color: var(--border-color);
          background: var(--bg-white);
        }
      }
      .dropdown-item {
        padding: 0;
        &:hover {
          background: var(--bg-white) !important;
        }
      }
    }
    &.open {
      .download-dropdown-menu {
        display: block;
      }
    }
  }
}

.grid {
  .grid-content {
    .left-buttons {
      .btn {
        &.minW-0 {
          min-width: unset;
          margin: 0px 5px;
        }
      }
    }
    .right-buttons {
      .btn {
        &.minW-0 {
          min-width: unset;
          margin: 0px 5px;
        }
      }
    }
  }
}

.search-input-wrapper {
  .bx {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 0.875rem;
  }
  .form-control {
    padding-left: 30px;
  }
}

.rc-pagination-item {
  color: rgba(0, 0, 0, 0.85);
  transition: none;

  font-size: 15px;
  line-height: 28px;
}
.rc-pagination-item-active {
  background: $primary;
  color: var(--white) !important;
  border-color: $primary;
  a {
    color: #1890ff !important;

    // &:hover {
    //   color: var(--white);
    // }
  }
}
.rc-pagination-prev {
  border-color: var(--input-border);
  color: var(--text-color);
  background: var(--input-bg);
  .rc-pagination-item-link {
    line-height: 28px;
  }
}
.rc-pagination-next {
  border-color: var(--input-border);
  color: var(--text-color);
  background: var(--input-bg);
  .rc-pagination-item-link {
    line-height: 24px;
  }
}
.rc-pagination-item {
  border-color: var(--input-border);
  color: var(--text-color);
}

.rc-pagination-disabled {
  .rc-pagination-item-link {
    border-color: var(--input-border);
    color: var(--text-color);
    background: var(--input-bg);
    line-height: 24px;
  }
  &:hover {
    .rc-pagination-item-link {
      background: var(--light);
      color: var(--text-color);
      border-color: var(--input-border);
    }
  }
}

.custom-accordian {
  .card-header {
    h5 {
      color: var(--dark);
      font-weight: 400;
    }
  }
}

.accordion {
  > .card {
    &.custom-accordian {
      overflow: unset !important;
    }
  }
}

.imageselect-text {
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 500;
  font-family: $font-heading;
}

.add-row {
  &.add-plus {
    position: absolute;
    right: 25px;
    bottom: 18px;
    background: var(--header-bg);
  }
}

.user-save {
  position: relative;
  cursor: pointer;
  .text-tooltip {
    display: none;
    position: absolute;
    right: 13px;
    border: 1px solid var(--secondary);
    border-radius: 4px;
    padding: 4px 8px;
    top: -21px;
    background: var(--secondary);
    align-items: center;
    justify-content: center;
    z-index: 1;
    .filter-btns {
      color: var(--light);
      em {
        cursor: pointer;
      }
    }
  }
  &:hover {
    .text-tooltip {
      display: flex;
    }
  }
}

.search-label {
  display: flex;
  align-items: center;
  .multiple-text {
    display: flex;
    align-items: center;
    .text-label {
      position: relative;
      background: var(--input-bg);
      padding: 5px 35px 5px 15px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-weight: 600;
      .remove-label {
        position: absolute;
        right: 4px;
        top: 4px;
        color: $primary;
        font-size: 1.25rem;
        cursor: pointer;
      }
    }
  }
}

.filter-labels {
  display: flex;
  align-items: center;
  .multiple-filter {
    position: relative;
    background: var(--input-bg);
    padding: 5px 35px 5px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .remove-label {
      position: absolute;
      right: 4px;
      top: 4px;
      color: $primary;
      font-size: 1.25rem;
      cursor: pointer;
    }
  }
}

// .filter-data-dropdown{
//     .custom-dropdown-menu{
//         min-height: 300px;
//         overflow-y: auto;
//         height: 100%;
//     }
// }

.error-msg {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  font-family: $font-heading;
}

.modalTitle {
  font-size: 1.25rem;
  color: var(--text-color);
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  .bx {
    font-size: 1rem;
    margin-right: 0.25rem;
  }
  label {
    margin-bottom: 0;
    font-weight: 500;
  }
}

.copy-content {
  font-size: 1rem;
  color: var(--text-color);
  cursor: pointer;
}

.error-page {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $font-heading;
  color: $dark;
  padding: 30px 20px;
  .error-middle {
    width: 100%;
    max-width: 800px;
    border-radius: 20px;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 15px;
  }
  h1 {
    font-size: 12.5rem;
    font-weight: 700;
    line-height: 1.1;
    margin: 0 0 20px;
    color: $success;
    .middle-image {
      margin: -50px -80px 0 16px;
      display: inline-block;
      position: relative;
      z-index: 1;
      vertical-align: middle;
    }
  }
  p {
    margin-bottom: 20px;
    font-size: 0.875rem;
  }
  .back-home {
    .btn {
      padding: 8px 20px 9px;
      font-size: 0.875rem;
      border: 1px solid $success;
      color: #fff;
      border-radius: 20px;
      background: $success;
    }
  }
}

.filter-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700 !important;
  font-size: 1.25rem;
  width: 100%;
  height: 220px;
  color: var(--text-color);
}

.text-readonly {
  display: inline-block;
  width: calc(100% - 126px);
  color: #475f7b;
  font-size: 15px;
  line-height: 1.3rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  word-break: break-word;
}

.otp-modal {
  .card-header {
    background: var(--bg-white);
    h4 {
      color: var(--text-color);
    }
  }
}

.load-details-wrapp {
  .user-title-info {
    &.user-details {
      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        .common-label {
          // width: 30%;
          font-size: 12px;
          text-transform: uppercase;
          color: #304156;
          font-weight: 700;
          font-family: "IBM Plex Sans", sans-serif;
        }
        // .text-readonly {
        //   width: 70%;
        // }
      }
    }
  }
}


.dsh-card{
  background: $white;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 3px 10px rgba(0, 0 ,0, 0.2);
  cursor: pointer;
  .dsh-title{
    height: 37px;
  }
  .icon{
    .bx{
      font-size: 2rem;
      color: var(--text-color);
    }
  }
  .dsh-content{
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
      font-size: 3rem;
      color: #475f7b;
    }
  }
}
.filter-btn-er{
  width: 200px;
}
.title-er{
  width: calc(100% - 200px);
}
.address-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  width: 100%;
}
.graph-block{
  margin-top: 30px;
  .graph-title{
    width: 80px;
    margin-right: 20px;
  }
  .chart-wrapp{
    width: calc(100% - 100px);
    padding: 0;
  }
}
.track-btn {
  position: absolute;
  right: 30px;
}
.badge-success{
  color: $white;
}

.btn-send{
  min-width: auto;
  background:$primary;
  color: $white;
  cursor: pointer;
  &:hover{
    color: $white !important;
  }
}
.rcs-btn {
  // position: absolute;
  // right: 30px;
  // bottom: 50px;
  display: flex;
  align-items: center;
}

.freightimg-box {
  width: 140px;
  max-width: calc(33.33% - 10.66px);
  @include media(down, $breakpoint-md) {
    max-width: calc(33.33% - 5.33px);
  }
  &:not(:last-child) {
    margin-right: 16px;
    @include media(down, $breakpoint-md) {
      margin-right: 8px;
    }
  }
}
.img-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ant-avatar {
  display: contents !important;
  img {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
  }
}

.ratting {
  font-size: 12px;
  margin-top: -5px;
}

.userProfilePic {
  position: absolute;
  top: 15px;
  right: 15px;
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.Toastify__toast-container {
  z-index: 111111;
}

.unsubscribe_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.history-timeline {
  flex-direction: column;
  li {
    position: relative;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      display: block;
      width: 2px;
      height: 62px;
      background: rgba(165, 166, 182, 0.3);
      position: absolute;
      left: 63px !important;
      top: 53px;
    }
    .verify-icon {
      margin-right: 10px;
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
    &.active {
      &::before {
        background: #f38915;
      }
      .verify-icon {
        background: rgba(243, 137, 21, 0.2);
        color: $primary;
      }
    }
  }
}

.user-detailes {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--input-border);
  .user-imgs {
    margin-right: 10px;
  }
}
.map-custom {
  position: relative;

  .track-details {
    background-color: #fff;
    padding: 15px;
    max-width: 500px;
    position: absolute;
    top: 200px;
    left: 10px;
    z-index: 99;
    width: 100%;
    .track-details-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;
      .ant-avatar {
        // display: flex !important;
        // align-items: center;
      }
      .chat-icon {
        img {
          width: 100%;
        }
      }
    }
    .track-details-bottom {
      display: flex !important;
      // padding-bottom: 1rem;
      .driver-one {
        flex-basis: 0;
        flex-grow: 1;
        width: 100%;
        &:nth-child(2) {
          border-left: 1px solid #ccc;
          padding-left: 1rem;
          margin-left: 1rem;
        }
        .driver-details {
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          .ant-avatar {
            // display: flex !important;
            // align-items: center;
            .carrier-name {
              span {
                font-weight: 600;
              }
              .driver-status {
                p {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        .eta {
          text-align: center;
          padding-top: 10px;
          span {
            font-weight: 700;
            text-decoration: underline;
          }
        }
      }
      .driver-two {
        width: 50%;
        .driver-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ant-avatar {
            display: flex !important;
            align-items: center;
            .carrier-name {
              span {
                font-weight: 600;
              }
              .driver-status {
                p {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

h6{
  &.title{
    font-size: 1.25rem;
  }
}

.parent-video_image{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .video-preview{
    width: 150px;
    height: 150px;
    margin-right: 15px;
    margin-bottom: 10px;
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .img-preview{
    width: 150px;
    height: 150px;
    margin-right: 15px;
     margin-bottom: 10px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}




.act-dropdown{
.action-linkdropdown {
  opacity: 0.5;
  width: 24px;
  height: 24px;
}
  .dropdown-toggle {
    box-shadow: none !important;
  }
  .no-downarrow {
    &::after {
      display: none;
    }
  }
    .dropdown-menu {
      box-shadow: 0 2px 8px 0 rgba(40, 41, 61, 0.04),
        0 16px 24px 0 rgba(96, 97, 112, 0.16);
      @include border-radius(12px);
      border: 0;
      padding: 30px 24px;
      min-width: 187px;
      right: 0px !important;
      left: auto !important;
      top: calc(100% + 15px) !important;
      // transform: none !important;
      display: block;
      visibility: hidden;
      opacity: 0;
      transform: translateY(40px) !important;
      transition: 0.15s ease all;
      &.show {
        display: block;
        visibility: visible;
        opacity: 1;
        transform: translateY(0px) !important;
        transition: 0.15s ease all;
      }
    }
    .dropdown-item {
      color: $dark;
      letter-spacing: -0.25px;
      background: none !important;
      position: relative;
      padding-left: 40px;
      transition: $transition-base;
      &:hover {
        color: $primary;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      span {
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      // line-height: 40px;
    }
  /* Starts Css for Breadcrumb */
  &.send-dropdown{
    .dropdown-menu{
      min-width: 100px;
      padding: 10px 10px;
      min-width: 130px;
      &::before{
        border: none;
      }
    }
  }
}



.filter-dropdown-menu{
  .form-group{
    .filter-label{
        width: 120px;
    }
    .form-control{
      width: calc(100% - 128px);
    }
  }
}
.filter-data-dropdown{
  width: 130px;
}
.search-boxes{
  width: calc(100% - 150px);
  padding: 10px;
  background: #e7edf3;
  border-radius: 4px;
}
.accounting-tab-data{
  padding: 0 30px 20px;
}
.invoice-listing{
  .content-area{
    padding: 0 30px;
  }
}
.act-dropdown{
 
  .action-linkdropdown{
    .bx{
      font-size: 20px;
      color: #000;
    }
  }
}
.accounting-tabel{
  .act-dropdown{
    .dropdown-menu{
      right: 31px !important;
      &::before{
        border: 0 !important;                
      }
    }
  }
}
.main-content-area {
  .table{
    &.common-datatable{
      &.accounting-tabel{
        thead{
          tr{
            th{
              &:last-child{
                text-align: center;
              }
            }
          }
          
        }
      }
    }
  }
}

.report-prints{
  padding: 50px 0;
}
.select-report-listing{
  max-width: 720px;
  width: 100%;
  margin: 30px auto;
}
.report-name{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 5px;
  background:$border-color;
  margin-bottom: 10px;
  border-radius: 4px;
  .custom-checkbox{
    width:36px;
  }
  h6{
    width: calc(100% - 46px);
    margin-right: 10px;
    margin-bottom: 0;
    color: $secondary;
  }
}


.activity-report{
  padding: 50px 20px;
}



.react-date-picker__inputGroup{
  .react-date-picker__inputGroup__input{
    width: 19px !important;
  }
}



.ld-details{
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--input-border);
  padding-bottom: 7px;
}

.usd-detailes{
  border-bottom: 1px solid var(--input-border);
}







