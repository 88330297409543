.loadingtop-sec {
    nav {
      &:not(.breadcrumb-block) {
        .breadcrumb {
          padding-top: 35px;
        }
      }
    }
    nav {
      &:not(.breadcrumb-block) {
        .breadcrumb-item {
          a {
            // color: rgba(#fff, 0.5);
          }
          & + .breadcrumb-item {
            &.active {
              color: #fff;
            }
            &::before {
            //   border-left-color: rgba(#fff, 0.5);
            }
          }
        }
      }
    }
  }
  .filter-checks {
    margin-right: -20px;
    input[type="checkbox"] {
      & + span {
        top: 0;
      }
    }
    @include media(up, $breakpoint-md) {
      margin-right: -15px;
    }
    @include media(down, $breakpoint-md) {
      &.custom-checkbox label {
        width: calc(33.33% - 20px);
      }
    }
    @include media(down, $breakpoint-xs) {
      &.custom-checkbox label {
        width: calc(50% - 20px);
      }
    }
  }
  .tag {
    letter-spacing: -0.12px;
    color: $dark;
    margin-bottom: 10px;
    .icon-ic-close {
      padding: 2px;
      width: 20px;
      height: 20px;
      color: $gray;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      margin-left: 10px;
    //   @extend %hoverto-theme;
    }
    .tag-wrapper & {
      margin-right: 20px;
    }
  }
  .tag-wrapper {
    margin-right: -20px;
  }
  
  /* Starts Css for Filter Section */
  .sorting-btn {
    // box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    //   0 2px 4px 0 rgba(96, 97, 112, 0.16);
    color: $dark;
    padding: 10px 10px 10px 16px;
    background: #fff;
    &:hover {
      color: $dark;
    }
    @include media(down, $breakpoint-sm) {
      padding: 8px 6px;
      font-size: 10px;
    }
    .icon-ic-sorting {
      font-size: 16px;
    }
    .icon-ic-filter {
      font-size: 13px;
    }
    &.active {
      background: $primary;
      color: #fff;
      span {
        color: #fff !important;
      }
    }
  }
  .filter-wrapper {
    position: relative;
    z-index: 3;
  }
  .overlay-block {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // z-index: -1;
    // background: rgb(#000, 0.4);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease-in;
  }
  .filterblockwrap {
    position: relative;
    z-index: 4;
  }
  .filterbutton-wrap {
    margin-top: -20px;
    margin-bottom: 12px;
    @include media(down, $breakpoint-md) {
      margin-top: -18px;
    }
  }
  .filter-button {
    li {
      &:not(:last-child) {
        margin-right: 1rem;
        @include media(down, $breakpoint-md) {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .filterbtn-mob {
    width: 36px;
    height: 36px;
    padding: 0 !important;
    font-size: 20px;
    &.active {
      background: $primary;
      color: #fff;
    }
  }
  /* Ends Css for Filter Section */
  
  .bid-listingview {
    width: calc(100% - 650px);
    @include media(down, $breakpoint-xl) {
      width: calc(100% - 340px);
    }
    @include media(down, $breakpoint-lg) {
      order: 2;
      width: 100%;
    }
    li {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 18px;
        @include media(down, $breakpoint-md) {
          margin-right: 12px;
        }
      }
    }
    .icon-bids {
      flex-shrink: 0;
      opacity: 0.5;
      margin-right: 8px;
      &.icon-ic-dateicon {
        opacity: 1;
      }
      @include media(down, $breakpoint-md) {
        margin-right: 6px;
      }
      &:not(.icon-ic-shipping) {
        font-size: 20px;
      }
    }
  }
  .bids-cardwrap {
    .bid-cardbox {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  .bids-cardwrap {
    padding-bottom: 40px;
  }
  .bid-topbx {
    h5 {
      width: 340px;
      padding-right: 10px;
      @include media(down, $breakpoint-xl) {
        width: 180px;
      }
      @include media(down, $breakpoint-sm) {
        width: auto;
      }
    }
    .dropdown-menu {
      top: calc(100% - 42px) !important;
    }
  }
  .bid-cardaction {
    width: 305px;
    padding-left: 10px;
    justify-content: end;
    margin-left: auto;
    @include media(down, $breakpoint-xl) {
      width: 160px;
    }
    @include media(down, $breakpoint-lg) {
      order: 1;
    }
    @include media(down, $breakpoint-sm) {
      width: auto;
    }
  }
  .action-linkdropdown {
    opacity: 0.5;
    width: 24px;
    height: 24px;
  }
  .bid-cardbox {
    .card-header {
      padding-bottom: 6px;
    }
    .dropdown-menu {
      top: -10px !important;
      bottom: auto !important;
    }
  }
  .pickups-block {
    max-width: 366px;
    position: relative;
    @include media(down, $breakpoint-lg) {
      padding-left: 30px;
    }
    &:not(:last-child) {
      @include media(up, $breakpoint-lg) {
        padding-right: 75px;
      }
      &::before {
        content: "";
        display: block;
        height: 2px;
        // background-color: rgb($primary, 0.2);
        position: absolute;
        left: 26px;
        right: -2px;
        top: 5px;
        @include media(down, $breakpoint-lg) {
          height: auto;
          width: 2px;
          left: 6px;
          right: auto;
          top: 21px;
          bottom: -7px;
        }
      }
    }
    > * {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .trackbtn {
    padding: 10px 15px;
    letter-spacing: -0.12px;
    .icon-ic-timeline {
      font-size: 10px;
    }
  }
  .enroute-btn {
    padding: 10px 11px;
    letter-spacing: -0.12px;
    span {
      font-size: 17px;
    }
  }
  .btnwith-icon {
    display: inline-flex;
    align-items: center;
    span {
      margin-right: 10px;
      @include media(down, $breakpoint-sm) {
        margin-right: 6px;
      }
    }
  }
  .pickupwrap-box {
    padding-top: 6px;
    padding-right: 20px;
    @include media(down, $breakpoint-sm) {
      width: 100%;
      padding-bottom: 10px;
    }
  }
  .pickup-topic {
    font-size: 20px;
    @include media(up, $breakpoint-lg) {
      margin-left: 7px;
      &.icon-ic-location {
        margin-top: -4px;
      }
    }
    @include media(down, $breakpoint-lg) {
      position: absolute;
      left: 0;
    }
  }
  .pickup-rect {
    width: 14px;
    height: 14px;
    background: $primary;
  }
  .bid-bottombx {
    @include media(down, $breakpoint-sm) {
      flex-wrap: wrap;
    }
  }
  .bid-topbx {
    @include media(down, $breakpoint-lg) {
      h5 {
        order: 0;
      }
    }
  }
  .offerprice-bottom {
    @include media(down, $breakpoint-xs) {
      display: flex;
      align-items: center;
      width: 100%;
      h6,
      h2 {
        margin-right: 10px;
      }
      .btn {
        margin-left: auto;
      }
    }
  }
  
  /* Starts Css for Load Details Page */
  .loadingtop-sec.loaddetails-top {
    min-height: 340px;
    padding-bottom: 72px;
  }
  .inner-detailsec {
    &:not(:last-child) {
    //   border-bottom: 1px solid rgba(#5a8dee, 0.2);
      padding-bottom: 0.5rem;
    }
  }
  .load-detailswrap {
    margin-top: -60px;
    position: relative;
    z-index: 3;
    padding-bottom: 143px;
    @include media(down, $breakpoint-md) {
      padding-bottom: 50px;
    }
    .card {
      &:not(:last-child) {
        margin-bottom: 40px;
        @include media(down, $breakpoint-md) {
          margin-bottom: 25px;
        }
      }
    }
  }
  .equipment-topbox {
    .small-text {
      margin-bottom: 20px;
      @include media(down, $breakpoint-md) {
        margin-bottom: 10px;
      }
    }
  }
  .freightimg-box {
    width: 140px;
    max-width: calc(33.33% - 10.66px);
    @include media(down, $breakpoint-md) {
      max-width: calc(33.33% - 5.33px);
    }
    &:not(:last-child) {
      margin-right: 16px;
      @include media(down, $breakpoint-md) {
        margin-right: 8px;
      }
    }
  }
  .equipment-smallbx {
    padding-bottom: 14px;
    h6 {
      @include media(up, $breakpoint-md) {
        padding-left: 24px;
      }
    }
  }
  .freight-img {
    max-width: 66px;
    margin-bottom: 5px;
  }
  .left-freightinfo {
    padding-right: 40px;
    max-width: 170px;
    @include media(down, $breakpoint-md) {
      padding-right: 20px;
    }
  }
  .right-freightinfo {
    .freight-box {
      padding-right: 40px;
      h6 {
        word-break: break-all;
      }
      @include media(down, $breakpoint-md) {
        padding-right: 20px;
      }
    }
  }
  .filter-activewrap {
    @include media(up, $breakpoint-md) {
      &:not(.active) {
        display: none;
      }
    }
  }
  .filterarea {
    .closebtn-filter {
      font-size: 20px;
      padding: 0 1px;
      height: 24px;
    }
    @include media(down, $breakpoint-md) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 50px 15px 15px;
      background: #fff;
      border-radius: 10px 10px 0 0;
    //   box-shadow: 0 2px 48px 0 rgba(40, 41, 61, 0.04),
    //     0 16px 144px 0 rgba(96, 97, 112, 0.16);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.15s ease;
      .card {
        box-shadow: none;
        padding: 0;
        position: static;
      }
      .card-body {
        padding-bottom: 0;
      }
      .close-filter {
        position: absolute;
        right: 0;
        top: 10px;
      }
      .filterbtn-wrap {
        padding-top: 20px;
      }
      &.active {
        opacity: 1;
        pointer-events: all;
        & + .overlay-block {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
  .verify-icon {
    width: 48px;
    height: 48px;
    // background: rgb(#b3c0ce, 0.2);
    border-radius: 50%;
    color: $gray;
    font-size: 22px;
    margin-bottom: 1.5rem;
    @include media(down, $breakpoint-sm) {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .pickups-timeinfo {
    span {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  .history-timeline {
    li {
      position: relative;
      @include media(down, $breakpoint-sm) {
        padding-left: 65px;
        min-height: 50px;
        padding-top: 5px;
      }
      &::before {
        content: "";
        display: block;
        width: auto;
        height: 4px;
        // background: rgb(#b3c0ce, 0.3);
        position: absolute;
        left: 55px;
        right: 8px;
        top: 22px;
        border-radius: 12px;
        @include media(down, $breakpoint-sm) {
          height: auto;
          width: 4px;
          left: 21px;
          bottom: 3px;
          top: 51px;
        }
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
      &.active {
        &::before {
          background: $primary;
        }
        .verify-icon {
        //   background: rgb($primary, 0.2);
          color: $primary;
        }
      }
  
      &:not(:last-child) {
        padding-right: 54px;
        @include media(down, $breakpoint-lg) {
          padding-right: 20px;
        }
        @include media(down, $breakpoint-sm) {
          padding-bottom: 50px;
        }
      }
    }
  }
  .carrier-icon {
    // background: rgb($primary, 0.2);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 30px;
    @include media(down, $breakpoint-lg) {
      width: 50px;
      height: 50px;
      font-size: 20px;
    }
  }
  .carrier-leftinfo {
    width: 300px;
    @include media(down, $breakpoint-lg) {
      width: 100%;
    }
  }
  .carrier-textinfo {
    width: 146px;
    @include media(down, $breakpoint-md) {
      width: auto;
    }
  }
  
  .carrier-boxes {
    padding: 1.5rem 0 0.5rem;
    &:first-child {
      padding-top: 1rem;
    }
    @include media(down, $breakpoint-lg) {
      flex-wrap: wrap;
      position: relative;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #d8d8d8;
    }
  }
  .carrier-boxesstatus {
    width: 200px;
    padding-right: 20px;
    padding-left: 40px;
    position: relative;
    @include media(down, $breakpoint-md) {
      width: auto;
      position: absolute;
      right: 0;
      top: 36px;
      padding: 0;
      margin: 0;
      .badge-small {
        padding: 5px 10px;
        @include border-radius(4px);
      }
    }
    .box-statusimg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      @include media(down, $breakpoint-md) {
        display: none;
      }
    }
  }
  .driver-infobox {
    padding-right: 15px;
    @include media(down, $breakpoint-md) {
      padding-left: 12px;
      [class*="icon-ic"] {
        background: transparent !important;
        color: $primary !important;
        width: auto;
        height: auto;
      }
    }
  }
  .accept-docswrap {
    @include media(up, $breakpoint-md) {
      padding-left: 68px;
      margin-top: -24px;
    }
    @include media(down, $breakpoint-md) {
      justify-content: center;
    }
  }
  .carrier-buttonswrap {
    @include media(down, $breakpoint-sm) {
      padding-left: 12px;
    }
  }
  .carrier-rightinfo {
    width: calc(100% - 300px);
    @include media(down, $breakpoint-lg) {
      width: 100%;
      margin-top: 5px;
    }
  }
  .width-32 {
    width: 32px;
    height: 32px;
  }
  .radius-50 {
    border-radius: 50%;
  }
  .document-statuswrap {
    [class*="icon-ic-"] {
      @include media(up, $breakpoint-md) {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
      @include media(down, $breakpoint-md) {
        margin-right: 0.5rem;
      }
    }
  }
  .outline-btn {
    padding: 10px 10px;
    display: inline-flex;
    align-items: center;
    @include media(down, $breakpoint-sm) {
      padding: 8px 6px;
      font-size: 12px;
    }
    &:not(:last-child) {
      margin-right: 10px;
      @include media(down, $breakpoint-sm) {
        margin-right: 4px;
      }
    }
    span {
      font-size: 18px;
      margin-right: 10px;
      @include media(down, $breakpoint-sm) {
        font-size: 14px;
        margin-right: 4px;
        position: relative;
        top: 1px;
      }
      &.icon-ic-eye {
        font-size: 14px;
      }
      &.icon-ic-close {
        font-size: 12px;
        @include media(down, $breakpoint-sm) {
          font-size: 8px;
          top: 2px;
        }
      }
      &.icon-ic-download {
        font-size: 15px;
      }
    }
  }
  .documentblock-box {
    width: 100%;
  }
  .driver-infoblock {
    padding-right: 0;
    width: calc(100% - 262px);
    @include media(down, $breakpoint-md) {
      width: 100%;
    }
  }
  .pending-badge {
    @include media(down, $breakpoint-sm) {
    //   color: $badge-primary !important;
    //   background-color: rgb($badge-primary, 0.2);
    }
  }
  .rejected-badge {
    @include media(down, $breakpoint-sm) {
    //   color: $badge-danger !important;
    //   background-color: rgb($badge-danger, 0.2);
    }
  }
  .doc-blockwrap {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  /* Ends Css for Load Details Page */
  /* Starts Css for Load Details Page */
  .add-signwrap {
    // font-family: $font-secondary;
    font-size: 36px;
    font-weight: normal;
    color: $black;
    line-height: 88px;
    height: 178px !important;
    margin-bottom: 22px;
    padding: 44px 0;
  }
  .addsign-modal {
    .signin-text {
      .btn {
        font-weight: $font-regular;
      }
    }
  }
  
  /* Starts Css for No Load Css */
  .empty-stateimg {
    max-width: 600px;
  }
  .noload-wrap {
    padding: 40px 0 82px;
    @include media(down, $breakpoint-md) {
      padding: 20px 0 40px;
    }
  }
  .nodriver-added {
    .empty-stateimg {
      max-width: 670px;
    }
  }
  /* Starts Css for Waiting to Approval Css */
  .waiting-wrap {
    padding: 10.75rem 0 12.875rem;
    @include media(down, $breakpoint-xxl) {
      padding: 5rem 0 6rem;
    }
  }
  
  .noequipment-wrap {
    padding: 40px 0 82px;
    @include media(down, $breakpoint-md) {
      padding: 20px 0 40px;
    }
  }
  
  .control-wrap.select-wrap.equipmentType.success-block {
    border: 1px solid #47b872 !important;
    border-radius: 4px;
  }
  .control-wrap.select-wrap.equipmentType.success-block:focus {
    border: 1px solid #47b872 !important;
    border-radius: 4px;
  }
  .select-shipper {
    border: 1px solid #47b872 !important;
  }
  // span.icon-pencil.text-primary.uploadImage{
  //   position: absolute;
  //   top: 75px;
  //   margin-left: 50%;
  // }
  
  .equipmentNotAvailable {
    margin: 0 auto;
  }
  
  label.form-control.icon-ic-attachfile.control-wrap.success-block {
    border: 1px solid #47b872 !important;
    border-radius: 4px;
    font-family: $font-family-base !important;
    line-height: 31px;
  }
  label.form-control.icon-ic-attachfile.control-wrap:before {
    float: right;
    line-height: 31px;
  }
  label.form-control.icon-ic-attachfile.control-wrap.success-block:before {
    content: "\e933";
    float: right;
    margin: 0px 29px;
    font-family: "icomoon" !important;
  }
  /* Starts Load Post Css */
  .cmd-pd-card {
    background: #fff;
    border-radius: 0 0 4px 4px;
    // box-shadow: 0 2px 8px 0 rgba(40, 41, 61, 0.04),
    //   0 16px 24px 0 rgba(96, 97, 112, 0.16);
    border: none;
    padding: 20px 30px;
    .card-title {
      padding-bottom: 20px;
      &.border-bt {
       border-bottom: 1px solid $border-color;
      }
    }
    .card-content {
      padding: 30px 0;
    }
  }
  .card-with-title {
    background: #fff;
    border-radius: 0 0 4px 4px;
    // box-shadow: 0 2px 8px 0 rgba(40, 41, 61, 0.04),
    //   0 16px 24px 0 rgba(96, 97, 112, 0.16);
    border: none;
    padding: 12px 30px;
  }
  .freight-table {
    white-space: nowrap;
    thead {
      tr {
        th {
          padding: 20px 20px;
          font-size: 14px;
          color: $gray;
          letter-spacing: -0.03px;
          line-height: 18px;
          font-weight: $font-bold;
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 20px;
          font-size: $font-size-base;
          color: $dark;
          letter-spacing: -0.25px;
          line-height: 40px;
          font-weight: $font-regular;
          &:first-child {
            padding-left: 0;
          }
          &:nth-child(6) {
            p {
              white-space: nowrap;
              width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              min-width: 215px;
              margin-bottom: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .equipment-block {
    padding: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .equipment-img {
      width: 60px;
      height: 60px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin: 0 20px;
    }
    .equipment-text {
      font-size: 12px;
      line-height: 18px;
      color: $dark;
      font-weight: $font-regular;
      max-width: 102px;
      width: 100%;
      text-align: center;
    }
  }
  .freight-types {
    display: flex;
    flex-wrap: wrap;
    .freight-type {
      padding: 8px 27px;
      border: 2px solid #fff;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      margin-bottom: 20px;
      margin-right: 1px;
      &:hover {
        border: 2px solid $primary;
        border-radius: 4px;
      }
      &.selectedFreight {
        border: 2px solid $primary;
        border-radius: 4px;
      }
      .freight-img {
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 5px;
      }
      .freight-text {
        font-size: 12px;
        line-height: 18px;
        font-weight: $font-bold;
      }
    }
  }
  .add-freight-modal {
    .modal-dialog {
      max-width: 1150px;
    }
    .modal-body {
      padding: 40px 30px 10px;
      @include media(down, $breakpoint-md) {
        padding: 20px 15px 10px;
      }
    }
    .modal-footer {
      padding: 14px 0 30px;
      justify-content: center;
    }
  }
  .upload-images-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .upload-image {
      width: 160px;
      height: 160px;
      @include border-radius(8px);
      margin-right: 18px;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    //   background: rgba(211, 211, 211, 0.2);
      &:last-child {
        margin-right: 0;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
  
      #attach-file {
        display: none;
      }
    }
    .upload-image-box {
    //   background: rgba(211, 211, 211, 0.2);
      width: 160px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
    //   box-shadow: 0 0 1px 0 rgba(7, 18, 37, 0);
      border-radius: 8px;
      cursor: pointer;
      .img-upload {
        cursor: pointer;
        #attach-file {
          display: none;
        }
      }
    }
  }
  
  .small-title {
    font-size: 14px;
    line-height: 18px;
    font-weight: $font-bold;
    letter-spacing: -0.03px;
  }
  
  .loadpost-btn {
    margin: 0 -15px;
    .btn {
      margin: 0 15px;
      @include media(down, $breakpoint-sm) {
        flex-grow: 1;
      }
    }
  }
  /*start custom dropdown css ***/
  .load-details-form {
    .custom-dropdown {
      .selected {
        a {
          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .options {
        ul {
          height: 315px;
          overflow-y: auto;
        }
      }
    }
  }
  .custom-dropdown {
    position: relative;
  
    .select-box__option{
        li{
          list-style-type:none;
        }
    }
    .selected {
      a {
        display: block;
        width: 100%;
        height: 54px;
        padding: 0.625rem 1.5rem;
        font-family: $font-family-base;
        font-size: 1.125rem;
        font-weight: $font-regular;
        line-height: 1.25;
        color: $gray;
        // background-color: rgba(#b3c0ce, 0.05);
        background-clip: padding-box;
        // border: 1px solid rgba(#b3c0ce, 0.05);
        @include border-radius(0.25rem);
        span {
          cursor: pointer;
          display: block;
          padding: 5px;
          font-weight: $font-bold;
          color: $dark;
          &.empty {
            color: $gray;
            font-weight: $font-regular;
          }
        }
        .equipment-text {
          color: #fff;
          font-weight: $font-bold;
        }
        @include media(down, $breakpoint-lg) {
          font-size: 1rem;
          padding: 10px 16px;
          height: 46px;
        }
      }
      span {
        &.value {
          display: none;
        }
      }
    }
    .options {
      position: relative;
      ul {
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        list-style: none;
        padding: 30px;
        position: absolute;
        left: 0px;
        top: 0px;
        width: auto;
        min-width: 785px;
        z-index: 999;
        @include border-radius(4px);
        // box-shadow: 0 2px 8px 0 rgba(40, 41, 61, 0.04),
        //   0 16px 24px 0 rgba(96, 97, 112, 0.16);
        @include media(down, $breakpoint-md) {
          min-width: 520px;
          justify-content: center;
          padding: 15px;
        }
        @include media(down, $breakpoint-sm) {
          min-width: 250px;
          padding: 10px;
        }
        li {
          &.selectedEquipment {
            a {
              background: $primary;
              color: #fff;
              transition: 0.2s ease;
              .equipment-text {
                color: #fff;
              }
            }
          }
          a {
            display: block;
            text-decoration: none;
            @include border-radius(4px);
            margin-bottom: 20px;
            &:hover {
              background: $primary;
              color: #fff;
              transition: 0.2s ease;
              .equipment-text {
                color: #fff;
                font-weight: $font-bold;
              }
            }
          }
        }
      }
      span {
        &.value {
          display: none;
        }
      }
    }
  }
  
  /*  starts Css for Carrier Load */
  .carrierload-page {
    @include media(up, $breakpoint-md) {
      padding-bottom: 96px;
    }
    .bid-cardbox {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
  .driverload-page {
    margin-top: 5rem;
    @include media(up, $breakpoint-xl) {
      .bid-topbx h5 {
        width: 250px;
      }
    }
    @include media(up, $breakpoint-md) {
      padding-bottom: 60px;
    }
  }
  .moveright-btn {
    width: 48px;
    height: 48px;
    // background: rgb($primary, 0.2);
    color: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include border-radius(12px);
    margin-left: 24px;
    font-size: 12px;
  }
  .clock-icon {
    font-size: 20px;
    @include media(down, $breakpoint-md) {
      font-size: 16px;
    }
  }
  .filterbtn-wrap {
    > * {
      &:not(:last-child) {
        margin-right: 30px;
        @include media(down, $breakpoint-md) {
          margin-right: 15px;
        }
      }
    }
  }
  .recommend-btn {
    span {
      font-size: 16px;
      @include media(down, $breakpoint-xs) {
        font-size: 12px;
      }
    }
  }
  .filter-scrollwrap {
    @include media(down, $breakpoint-md) {
      max-height: 210px;
      margin: 0 -15px;
      overflow: auto;
      .row {
        margin: 0;
      }
    }
  }
  .postedbid-wrap {
    @include media(down, $breakpoint-sm) {
      width: 100%;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
  .offerprice-wrap {
    @include media(down, $breakpoint-sm) {
      width: 100%;
      justify-content: space-between;
    }
  }
  .carrier-filter {
    @include media(up, $breakpoint-md) {
      border: 2px solid $primary;
      &::before {
        content: "";
        width: 46px;
        height: 18px;
        display: inline-block;
        // background: url("../../images/triangle-shape.svg") no-repeat;
        position: absolute;
        left: 500px;
        top: -16px;
      }
    }
  }
  .result-text {
    letter-spacing: -0.45px;
  }
  
  /* Starts Css for Assign driver modal */
  .assigndriver-modal {
    .modal-dialog {
      max-width: 420px;
    }
  }
  .radio-custombox {
    justify-content: center;
    margin-bottom: 24px;
    .btn-group-toggle {
      margin: 0 -13px;
      .btn {
        margin: 0 13px;
      }
    }
    .btn {
      background: transparent;
      color: $dark;
      outline: none;
      box-shadow: none;
      padding: 0;
      width: 120px;
      font-size:12px;
      padding: 11px 20px 32px;
      @include border-radius(4px !important);
      margin: 0 13px;
      &.active {
        background-color: $primary !important;
        border-color: $primary !important;
        // box-shadow: 0 0 2px 0 rgba(40, 41, 61, 0.04),
        //   0 4px 8px 0 rgba(96, 97, 112, 0.16);
        .assign-driveic {
          color: #fff;
        }
      }
    }
  }
  .select-wrap {
    margin-bottom: 24px;
  }
  .assigndriver-modal {
    .modal-body {
      padding: 20px 30px 40px;
    }
  }
  .assign-driveic {
    width: 60px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    // color: rgba(#000, 0.4);
  }
  .min-w-165 {
    min-width: 165px;
  }
  
  /* Starts Css for Carrier Load details Page */
  .carrier-detailwrapper {
    .card {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
  .history-timeline {
    @include media(up, $breakpoint-md) {
      padding: 55px 0;
    }
  }
  
  /* Starts Css for Carriers driver Page */
  .user-initial {
    width: 32px;
    height: 32px;
    background: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: $font-bold;
    @include border-radius(50%);
    margin-right: 8px;
    font-size: 10px;
    letter-spacing: 0.1px;
  }
  .driver-status {
    font-size: 18px;
  }
  .lightdark-btn {
    // background: rgb($primary, 0.2);
    // border: rgb($primary, 0.2);
    color: $primary;
    padding: 9px 16px;
    &:disabled,
    &.disabled {
    //   background: rgba(#b3c0ce, 0.2);
      color: $gray;
      opacity: 1;
    }
  }
  .changestatus-btn {
    margin-right: 26px;
    @include media(down, $breakpoint-lg) {
      margin-right: 15px;
    }
  }
  .delete-btn {
    // color: rgba(#000, 0.5);
  }
  .driversblock-wrap {
    padding-bottom: 135px;
    @include media(down, $breakpoint-lg) {
      padding-bottom: 50px;
    }
    .slider {
      background: #ccccd6;
    }
  }
  .adddriver-modal {
    .modal-dialog {
      max-width: 810px;
    }
  }
  
  /* Starts Css for Carrier Details Page */
  .doc-driverright {
    max-width: 260px;
  }
  .doc-driverleft {
    width: 260px;
  }
  .doc-drivercenter {
    width: calc(100% - 520px);
  }
  .docs-carierbx {
    .carrier-icon {
      @include media(up, $breakpoint-lg) {
        width: 60px;
        height: 60px;
      }
    }
    &:not(:last-child) {
    //   border-bottom: 1px solid rgb(#b3c0ce, 0.6);
    }
  }
  .driver-smallic {
    font-size: 25px !important;
  }
  .shipper-smallic {
    font-size: 22px !important;
  }
  .bid-smallbx {
    padding: 1.5rem 0 0.5rem;
    &:first-child {
      padding-top: 1rem;
    }
  }
  .docs-carierright {
    width: 400px;
    @include media(down, $breakpoint-xl) {
      width: 100%;
    }
  }
  .docs-carierleft {
    width: calc(100% - 400px);
    @include media(down, $breakpoint-xl) {
      width: 100%;
    }
    [class*="icon-ic-"] {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &.icon-ic-price,
      &.icon-ic-status {
        font-size: 20px;
      }
    }
  }
  .bidtruck-type {
    width: 160px;
    padding-right: 10px;
    @include media(down, $breakpoint-sm) {
      width: 50%;
    }
  }
  .bidtruck-code {
    width: 98px;
    padding-right: 10px;
    @include media(down, $breakpoint-sm) {
      width: 50%;
    }
  }
  .bidtruck-price {
    padding-right: 10px;
    width: 195px;
    @include media(down, $breakpoint-sm) {
      width: 50%;
    }
  }
  .bidtruck-status {
    padding-right: 10px;
    @include media(down, $breakpoint-sm) {
      width: 50%;
    }
  }
  
  // .custom-slop-dropdown{
  //   &.dropdown-menu{
  //     width: 100%;
  //     border-radius: 0 0 4px 4px;
  //     background: #fff;
  //     padding: 0;
  //   }
  //   .dropdown-header{
  //     padding: 8px 16px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     width: 100%;
  //     background: $primary;
  //     color: #fff;
  //   }
  // }
  .custom-slop-dropdown {
    width: 100%;
    border-radius: 0 0 4px 4px;
    background: #fff;
    padding: 0;
    position: absolute;
    z-index: 99;
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
    -webkit-transition: 0.15s ease all;
    transition: 0.15s ease all;
    // box-shadow: 0 2px 8px 0 rgb(40 41 61 / 4%), 0 16px 24px 0 rgb(96 97 112 / 16%);
    -moz-border-radius: 12px;
    border: 0;
    min-width: 187px;
    right: 0px !important;
    left: auto !important;
    top: -webkit-calc(100% - 16px) !important;
    top: calc(100% - 16px) !important;
    display: none;
  
    .dropdown-header {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: $primary;
      color: #fff;
    }
    &.active {
      display: block;
    }
  }
  .view-rcs-btn {
    &:hover {
      color: #fff !important;
    }
  }
  .btn {
    &:hover {
      .view-rcs-btn {
        color: #fff !important;
      }
    }
  }
  
  .location-wrap {
    padding: 25px;
    // border-bottom: 1px solid rgba(#5a8dee, 0.2);
    ul {
      padding-left: 0;
      .pickup-view {
        position: relative;
        padding-left: 24px;
        margin-bottom: 15px;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          width: 10px;
          height: 10px;
          background: $primary;
        }
        &::after {
          content: "";
          position: absolute;
          width: 2px;
          height: 90%;
          background: $primary;
          left: 4px;
          top: 21px;
        }
      }
      .dropoff-view {
        position: relative;
        padding-left: 18px;
        &::before {
          position: absolute;
          left: 0;
          top: 0;
          content: "\e908";
          color: $primary;
          font-family: "icomoon" !important;
        }
      }
    }
  }
  .map-custom {
    padding-top: 80px;
  }
  .load-map {
    margin: 50px 0;
    position: absolute;
    width: 100%;
    top: 52px;
    max-width: 360px;
  }
  .load-map-btn {
    &.dropdown {
      .dropdown-toggle {
        padding: 0;
        background: transparent;
        border: none;
        width: 100%;
        &::after {
          border-top: 0.3em $dark;
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
      .control-wrap {
        .select-box__control {
          background-color: #fff !important;
        }
      }
    }
    .btn {
      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }
      &:focus {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
  
  .status-wrap {
    padding: 30px 16px;
    .btn {
      width: auto !important;
      font-size: 13px;
      line-height: 18px;
      padding: 7px 25px;
      &.btn-outline-success {
        // background: rgba(71, 184, 114, 0.2);
        color: $success;
        border: none;
      }
    }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 15px;
      padding-left: 0;
      li {
        margin-right: 35px;
        &:last-child {
          margin-right: 0;
        }
        .driver-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: $primary;
          color: #fff;
        }
      }
    }
  }
  
  .driver-loads {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .chart-details {
    width: 100px;
  }
  .graph-info {
    width: calc(100% - 140px);
    margin-right: 30px;
  }
  .chart-titles {
    width: 200px;
    margin-right: 30px;
  }
  .chart-image {
    width: calc(100% - 230px);
  }
  
  .estimateClose {
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 13px;
    cursor: pointer;
  }
  